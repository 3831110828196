@use "./variables" as *;

@mixin inputGroup($width) {
  position: relative;
  width: $width;
  .input {
    width: 100%;
    height: 3.313rem;
    padding-left: 0.875rem;
    border: solid 1px $inputBorder;
    border-radius: 8px;
    font-size: 1rem;
    color: $inputPlaceholder;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .input:focus {
    outline: none;
    border: 1.5px solid $complementaryBlue;
  }
  .input:focus ~ label {
    transform: translateY(-50%);
    background-color: #fff;
    padding: 0.2rem 0.5em;
    color: #2196f3;
  }
  .input:valid ~ label {
    transform: translateY(-50%);
    background-color: #fff;
    padding: 0.2rem 0.5em;
  }
  .inputLabel {
    position: absolute;
    left: 0.875rem;
    color: $inputLabel;
    font-size: 0.75rem;
    pointer-events: none;
    line-height: 1.5rem;
    transform: translateY(0.938rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

$media: (
  xs: 453px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 1920px,
);

@mixin mq($breakpoint) {
  @each $breakpoints, $size in $media {
    @if $breakpoint==$breakpoints {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  }
}
