@use "../abstracts" as *;
@use "../components/productFeature";

.mainProduct {
  display: grid;
  grid-template: 1fr 1fr / 45% 55%;
  // grid-column: 2 / 3;
  grid-row: 2 / 3;
  row-gap: 2rem;
  @include mq(md) {
    grid-row: 1 / 2;
    padding-top: 2rem;
  }
  @include mq(lg) {
    grid-row: 2 / 3;
    padding-top: 0;
  }
  .productImage {
    grid-row: 1 / 3;
    img {
      height: 60%;
      @include mq(xs) {
        height: 90%;
        padding: 0 0 0 1rem;
      }
      @include mq(md) {
        height: 60%;
      }
    }
  }
  .productFeatures {
    ul {
      list-style-type: none;
      margin: 0.5rem 0 0 0;
      @include mq(xs) {
        margin: 0.5rem 1rem 0 0;
      }
    }
  }
  .productPrice {
    color: $complementaryBlue;
    :nth-child(2) {
      font-size: 2rem;
      font-weight: bold;
      margin-top: 0.25rem;
    }
  }
}