$formInputPlaceholder: #6b9bc4;
$mainBackground: #e5f2ff;
$complementaryBlue: #3399ff;
$mainInfoTitle: #2aa5ff;
$mainInfoSubTitle: #093550;
$mainInfoMessage: #58727e;
$productFeature: #617d8a;
$inputBorder: #b1cde9;
$inputPlaceholder: #58727e;
$inputLabel: #6b9bc4;
$submitInfo: #6b8996;
$submitSubInput: #58727e;