@use "../abstracts/variables" as *;

.productFeature {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  img {
    height: 1.625rem;
    margin-right: 0.5rem;
  }
  p {
    color: $productFeature;
  }
}