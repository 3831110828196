@use "../abstracts" as *;

header {
  display: flex;
  height: 8rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  padding: 0 11.46% 0 11.46%;
  img {
    width: 100%;
  }
}