@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.progressBar {
  position: absolute;
  width: 85%;
  max-width: 17.875rem;
  height: 1rem;
  top: -0.5rem;
  z-index: 1;
  background-color: #fff;
  border-radius: 1rem;
  @include mq(xs) {
    width: 17.875rem;
  }
  :nth-child(1) {
    display: block;
    width: 3.5rem;
    height: 100%;
    border-radius: 1rem;
    background-color: $complementaryBlue;
    @include mq(xs) {
      width: 4.5rem;
    }
  }
  :nth-child(2) {
    display: flex;
    position: absolute;
    top: -0.75rem;
    left: 2.875rem;
    width: 2.5rem;
    height: 2.5rem;
    color: #fff;
    border-radius: 2rem;
    background-color: $complementaryBlue;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    @include mq(xs) {
      left: 3.875rem;
    }
  }
}