@use "../abstracts" as *;

.mainInfo {
  grid-row: 1 / 2;
  padding-top: 2rem;
  max-width: 22.8rem;
  @include mq(lg) {
    padding-top: 6.75rem;
  }
  h2 {
    color: $mainInfoTitle;
    font-size: 1.125rem;
  }
  :nth-child(2) {
    font-size: 2rem;
    font-weight: bold;
    color: $mainInfoSubTitle;
    margin: 0.5rem 0 1.5rem 0;
  }
  :nth-child(3) {
    color: $mainInfoMessage;
    margin-right: 1rem;
  }
}
