@use "../abstracts" as *;

.mainForm {
  height: 49.625rem;
  // grid-row: 1 / 3;
  grid-row: 3 / 4;
  margin-top: 3.813rem;
  padding: 3rem 0.8rem 1.5rem 0.8rem;
  background-color: #fff;
  border-radius: 8px;
  @include mq (sm) {
    height: 45.625rem;
    padding: 3rem 4rem 1.5rem 2.4rem;
  }
  @include mq(md) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
  @include mq(lg) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
  .formSection {
    margin-bottom: 2rem;
    h2 {
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .inputGroup {
      @include inputGroup(100%);
    }
    .inputGroupSmall {
      @include inputGroup(100%);
      margin-bottom: 1rem;
      @include mq(sm) {
        @include inputGroup(40%);
        display: inline-block;
      }
    }
    .inputGroupMedium {
      @include inputGroup(100%);
      @include mq(sm) {
        @include inputGroup(55%);
      float: right;
      }
    }
    :nth-child(2) {
      margin-bottom: 1rem;
    }
  }
  .formSectionSubmit {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .visuallyHidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    button[type="submit"] {
      width: 100%;
      height: 2.75rem;
      display: flex;
      justify-content: space-between;
      padding: 0 0.875rem 0 1rem;
      border: 1px solid $complementaryBlue;
      border-radius: 8px;
      background-color: $complementaryBlue;
      font-size: 1.125rem;
      font-weight: bold;
      color: #fff;
      align-items: center;
      transition: 0.2s;
      cursor: pointer;
      @include mq(xs) {
        min-width: 11rem;
        width: 60%;
      }
      img {
        height: 1rem;
      }
    }
    button[type="submit"]:hover {
      background-color: #fff;
      color: $complementaryBlue;
    }
    a {
      display: flex;
      align-items: center;
      color: $submitSubInput;
    }
    p {
      grid-column: 1 / 3;
      font-size: 0.75rem;
      margin-top: 0.3rem;
      color: $submitInfo;
    }
  }
}
